import { QueueListIcon } from "@heroicons/vue/20/solid";

export const propertyDiagramVisualFeatures = {
  order: 3,
  name: "Visual features of the property diagram",
  subcategory: "Properties",
  icon: QueueListIcon,
  iconColor: "text-blue-400",
  markdown: `# Visual features of the property diagram

  The diagram is showing you a vertical section of one or more properties, from the sky down to below the ground. The diagram contains the following horizontal sections (in descending order):

  {% callout type="tip" %}
  **Tip:** New to Tower Hunt? Learn about {% inlineRouterLink articleId="the-role-of-the-property-diagram" %}the role of the property diagram{% /inlineRouterLink %} and {% inlineRouterLink articleId="what-is-a-property?" %}how properties are used in Tower Hunt{% /inlineRouterLink %}.
  {% /callout %}

  ## Events timeline

  Above the diagram is a {% inlineRouterLink articleId="timeline" %}timeline{% /inlineRouterLink %}. Use it to see recent activity at the related properties. You can also {% inlineRouterLink articleId="time-travel" %}time travel{% /inlineRouterLink %} and select events.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesTimeline0.png)

  ## Property selector

  This section displays the name of each property that is part of the diagram. When multiple properties are present, each property has a {% inlineAppIcon iconName="xMark" %}**close button**{% /inlineAppIcon %} to the right of the name and the currently selected property has an orange background.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesSelector0.png)

  ## Air rights (optional)

  Blocks in this section represent air rights. In Tower Hunt, air rights serve two important functions:

  - A container for an additional "air layer" property diagram, which can illustrate nearly everything that the property diagram can. View and edit the air layer diagram by selecting the air rights and clicking {% inlinePropertyDiagramIcon name="viewAirLayer" /%} in the {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}control panel{% /inlineRouterLink %}.
  - A container for **potential** floor areas, which illustrates that a fee parcel may support more space in the future.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesAirRights0.png)

  ## Land coverings

  The largest horizontal section contains above- and below-grade land coverings such as buildings and parking lots. A thick horizontal black line represents ground level. Land coverings can sit atop one or more fee parcels.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesLandCoverings0.png)

  ## Land covering ownership units (optional)

  When an entire building component within a larger structure is controlled via condominium (or co-op) unit, you will see the unit appear as a green (or blue) block in this section. Leaseholds may sit atop the unit.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesLandCoveringOwnershipUnits0.png)

  ## Leaseholds (optional)

  When a fee parcel is controlled via ground lease, a yellow leasehold block appears in this section. Sub-leasehold blocks may sit atop the leasehold block, with each successive sub-leasehold being colored more red.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesLeaseholds0.png)

  ## Fee parcels

  Every Tower Hunt property contains at least one fee parcel, which appears as a gold block in this section. Additional parcels may be added to illustrate more complex properties.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesFeeParcels0.png)

  ## Reciprocal easements (optional)

  These green blocks sit underneath one or more fee parcels, indicating that a reciprocal easement is present.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesReciprocalEasements0.png)

  ## Control panel

  Below the diagram is a control panel with three parts (from left to right): the ({% inlineRouterLink %}expandable{% /inlineRouterLink %}) {% inlineRouterLink %}Mini Map{% /inlineRouterLink %}, the center console, and the legend. The control panel empowers dozens of useful actions.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesControlPanel0.png)

  When the Mini Map is expanded, it reveals the resizeable {% inlineRouterLink %}Compact Map{% /inlineRouterLink %}.

  ![Property diagram screenshot](https://assets.towerhunt.com/site/diagramVisualFeaturesCompactMap0.png)

  {% callout type="tip" %}
  **Tip:** When you're ready to start exploring property diagrams in greater depth, visit our {% inlineRouterLink articleId="understand-the-property-diagram-control-panel" %}guide to the control panel{% /inlineRouterLink %}.
  {% /callout %}`,
};
